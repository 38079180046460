import React from 'react';
import {
  Menu,  MenuButtonModifiers
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

// copy pasted from react-menu
type RenderProp<M, R = React.ReactNode> = R | ((modifiers: M) => R);
/**
 * Just the menu but with styles and stuff already imported!
 */
const DropdownMenu = (props: {children: React.ReactNode, menuButton:  RenderProp<MenuButtonModifiers, React.ReactElement>}) => <Menu menuButton={props.menuButton}>{props.children}</Menu>
export default DropdownMenu;
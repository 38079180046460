import * as React from "react";
import styled from "styled-components/macro";
import { AppColors } from "../Styling";
import { propsToPx } from "../Layout/Layout";

export const Heading1: React.FC = styled.h1`
  color: inherit;
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 400px) {
    font-size: 21px;
  }
`;

export const Heading2: React.FC = styled.h2`
  color: inherit;
  font-size: 24px;
  font-weight: 300;
  @media (max-width: 400px) {
    font-size: 21px;
  }
`;

export const Heading3: React.FC = styled.h3`
  color: inherit;
  font-size: 28px;
  font-weight: 500;
  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

export const Heading4: React.FC = styled.h4`
  color: inherit;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

export const Heading5: React.FC = styled.h5`
  /* margin-bottom: 10px; */
  color: inherit;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

export const Heading6: React.FC = styled.h6`
  color: ${AppColors.neutral.darkGrey};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`;

type CategoryHeadingProps = {
  fontSize?: number;
  bottomMargin?: number;
}

export const CategoryHeading: React.FC<CategoryHeadingProps> = styled(Heading1) <CategoryHeadingProps>`
  color: ${AppColors.neutral.mediumGrey};
  font-size: ${props => props.fontSize ? propsToPx(props.fontSize) : '42px'};
  font-weight: 300;
  opacity: 0.8;
  margin-bottom: ${props => props.bottomMargin ? propsToPx(props.bottomMargin) : '25px'};
`

export const SubCategoryHeading: React.FC = styled(Heading2)`
  color: ${AppColors.neutral.darkestGrey};
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin: 5px 0;
`

export default Heading1;

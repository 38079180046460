import { User } from "@auth0/auth0-spa-js";
import { SupportalRoutes } from "./SupportalRoutes";
import { AuthProvider } from "contexts/AuthContext";
import { UserDataProvider } from "contexts/UserDataContext";
import { GraphQLContext } from "contexts/GraphQLContext";
import { AppHeader } from "components/app/AuthenticatedApp/AppHeader";
import RoleBasedComponent from "components/common/RoleBasedComponent/RoleBasedComponent";
import { Role } from "components/app/users/types";
import { logging } from "logging";
import Navigation from "../Navigation/Navigation";
import { Box, ResponsiveGridColumnLayout } from "components/common/Layout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FavoritesProvider } from "../Favorites/useFavorites";

interface AuthenticatedAppProps {
  user: User;
}
export const AuthenticatedApp = (props: AuthenticatedAppProps) => {
  const { user } = props;
  logging.userAuth(user.sub ?? "unknown_user");
  return (
    <AuthProvider defaultUser={user}>
      <GraphQLContext>
        <UserDataProvider userId={user.sub}>
          <AppHeader />
          <RoleBasedComponent
            fallbackComponent={<NoSoupForYou />}
            require={[
              Role.ADMIN,
              Role.INTERNAL_SUPPORT,
              Role.FIELD_OPERATOR,
              Role.SUPPORT_ADMIN,
            ]}
          >
            <ResponsiveGridColumnLayout columns={[.25, 1.75]}>
              <Navigation />
              <Box padding={15}>
                <FavoritesProvider>
                  <SupportalRoutes />
                </FavoritesProvider>
              </Box>
            </ResponsiveGridColumnLayout>
            <ToastContainer />
          </RoleBasedComponent>
        </UserDataProvider>
      </GraphQLContext>
    </AuthProvider>
  );
};

const NoSoupForYou = () => (
  <h1>Supportal is only accessible to internal users only I am afraid.</h1>
);

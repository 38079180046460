import styled from "styled-components"

/**
 * This layout provides a responsive grid with columns and rows.
 */
const Dashboard = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-areas:
        "welcome favorites";
    grid-gap: 50px;
    padding: 10px;

    .welcome {
        grid-area: welcome;
        height: 225px;
    }

    .favorites {
        grid-area: favorites;
        height: auto;
    }

    > * {
        padding: 25px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        overflow: auto;
    }
`

export default Dashboard;
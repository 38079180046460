import { VerticalLayout } from "components/common/Layout";
import { Heading1 } from "components/common/Heading";
import styled from "styled-components";
import Dashboard from "components/common/Layout/Dashboard";
import { FavoritesWidget } from "../Favorites";
export interface SupportalMainProps {
  default?: boolean;
}

export const SupportalMain = (props: SupportalMainProps) => {
  return (
    <Dashboard>
      <VerticalLayout childSpacing={4} className="welcome">
        <Heading1>Welcome to Supportal!</Heading1>
        <InfoText>Supportal is a web application that automates some
          frequent support tasks, enabling those in customer-facing
          roles to close tickets without help from engineering.</InfoText>
      </VerticalLayout>
      <VerticalLayout childSpacing={4} className="favorites">
        <FavoritesWidget />
      </VerticalLayout>
    </Dashboard>
  );
};

const InfoText = styled.p`
  font-size: 18px;
  line-height: 25px;
`;
import { useState } from 'react';

type ReturnType<T> = [
    T | null,
    (value: T) => void,
    () => void
];

function useLocalStorage<T>(key: string, initialValue: T): ReturnType<T> {
    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = (): T => {
        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    };

    const [storedValue, setStoredValue] = useState<T>(readValue);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)): void => {
        if (typeof window == 'undefined') {
            console.warn(
                `Tried setting localStorage key “${key}” even though environment is not a client`
            );
        }

        try {
            const newValue = value instanceof Function ? value(storedValue) : value;

            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(newValue));

            // Save state
            setStoredValue(newValue);

            // We dispatch a custom event so every useLocalStorage hook are notified
            window.dispatchEvent(new Event('local-storage'));
        } catch (error) {
            console.warn(`Error setting localStorage key “${key}”:`, error);
        }
    };

    // Return a function to delete the value from localStorage
    const removeValue = (): void => {
        try {
            window.localStorage.removeItem(key);
            setStoredValue(null);
        } catch (error) {
            console.warn(`Error removing localStorage key “${key}”:`, error);
        }
    };

    return [storedValue, setValue, removeValue];
}

export default useLocalStorage;
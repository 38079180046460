import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { AppColors } from "../Styling";

/**
 * Only use me for things that leave the SPA.
 * Otherwise, use the `Link` component from `react-router-dom`
 */
export const HyperLink = styled.a`
  color: ${AppColors.neutral.darkestGrey};
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  & :visited {
    color: ${AppColors.neutral.darkestGrey};
  }
`;

/**
 * Only use me for things that leave the SPA.
 * Otherwise, use the `Link` component from `react-router-dom`
 */
export const HyperLink2 = styled.a`
  color: ${AppColors.primary.primaryGreen};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  & :visited {
    color: ${AppColors.primary.primaryGreen};
  }
`;

/**
 * A dark grey internal Link. Not great for accessibility.
 * Can be used for links that are internal to the application
 */
export const Link1 = styled(Link)`
  color: ${AppColors.neutral.darkestGrey};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  & :visited {
    color: ${AppColors.neutral.darkestGrey};
  }
  &:after,
  a:visited:after {
    content: "";
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    background: ${AppColors.neutral.darkGrey};
    transition: 0.2s;
  }
  &:hover:after,
  &:visited:hover:after {
    width: 100%;
  }
`;

/**
 * A green internal Link
 * Can be used for links that are internal to the application
 */
export const Link2 = styled(Link)`
  color: ${AppColors.primary.primaryGreen};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  & :visited {
    color: ${AppColors.primary.primaryGreen};
  }
`;

export const Link3 = styled(Link)`
  color: ${AppColors.neutral.darkGrey};
  text-decoration: none;
  font-weight: 500;
  transition: color .5s ease;
  &:before {
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    content: '.';
    width: 2px;
    color: transparent;
    background: ${AppColors.neutral.lightGrey};
    transition: background .5s ease;
  }

  &:hover {
    color: ${AppColors.primary.primaryGreen};
  }

  &:hover:before {
    background: ${AppColors.primary.primaryGreen};
  }
`;

/**
 * An interal Link that looks like a button.
 * Also not very good for accessabillity
 *
 */
export const ButtonLink = styled(Link)`
  padding: 16px;
  color: #fafafa;
  background-color: ${AppColors.primary.primaryGreen};
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  min-width: 300px;
  width: 20%;
  max-width: 600px;
  outline: none;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  &:visited {
    color: #fafafa;
  }
  &:hover {
    color: #fafafa;
  }
  &:active {
    color: #fafafa;
  }
  &:focus {
    color: #fafafa;
  }
`;

export const NavLink = styled(Link)`
  color: ${AppColors.neutral.darkGrey};
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${AppColors.primary.secondaryGreen};
    content: "";
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(8px);
  }

  &:hover:after {
    opacity: 0.5;
    transform: translateY(2px);
  }
`;
import { CategoryHeading, Heading1 } from "components/common/Heading"
import { Box } from "components/common/Layout"
import { Link3 } from "components/common/Link"
import { useFavorites } from "./useFavorites";
import { Favorite } from "./types";
import { ListItem, ListOfLinks } from "components/common/List/CategoryPageLinks";



export const FavoritesWidget = () => {
    const [state] = useFavorites();

    const categories = state.reduce((category: any, favorite: Favorite) => (category.includes(favorite.category) ? category : [...category, favorite.category]), []).sort();

    const favoriteLinkItems = categories.map((category: string) => {
        return (
            <div key={category}>
                <CategoryHeading fontSize={5} bottomMargin={1}>{category}</CategoryHeading>
                {state.filter((favorite: Favorite) => favorite.category === category).map((favorite: Favorite, index: number) => {
                    return (
                        <ListOfLinks key={index}>
                            <ListItem padding={1} key={favorite.text}>
                                <Link3 target={favorite.target} to={favorite.route}>{favorite.text}</Link3>
                            </ListItem>
                        </ListOfLinks>

                    )
                })}
            </div>
        );
    });


    return (
        <>
            <Heading1>My Favorites</Heading1>
            {favoriteLinkItems.length > 0 ? favoriteLinkItems : <Box>You have no favorites</Box>}
        </>
    )
}
import { MenuItem } from "@szhsin/react-menu";
import { Divider } from "components/common/Layout";
import { Link1 } from "components/common/Link";
import RoleBasedComponent from "components/common/RoleBasedComponent/RoleBasedComponent";
import { Role } from "../users/types";

export const MobileNavigation = () => {
  return (
    <>
      <RoleBasedComponent
        require={[
          Role.ADMIN,
          Role.FIELD_OPERATOR,
          Role.INTERNAL_SUPPORT,
          Role.CUSTOMER_ADMIN,
          Role.SUPPORT_ADMIN,
        ]}
      >
        <MenuItem>
          <Link1 to={"/baselines"}>Baselines</Link1>
        </MenuItem>
      </RoleBasedComponent>
      <MenuItem>
        <Link1 to={"/building-model"}>Building Model</Link1>
      </MenuItem>
      <RoleBasedComponent require={[Role.ADMIN]}>
        <MenuItem>
          <Link1 to={"/ingest"}>Ingest</Link1>
        </MenuItem>
        <MenuItem>
          <Link1 to={"/integrations"}>Integrations</Link1>
        </MenuItem>
      </RoleBasedComponent>
      <MenuItem>
        <Link1 to={"/measures"}>Measures</Link1>
      </MenuItem>
      <RoleBasedComponent require={[Role.ADMIN]}>
        <MenuItem>
          <Link1 to={"/sources"}>Sources</Link1>
        </MenuItem>
      </RoleBasedComponent>
      <MenuItem>
        <Link1 to={"/users"}>Users</Link1>
      </MenuItem>
      <Divider width={40}></Divider>
    </>
  );
};

import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticatedApp } from "./components/app/AuthenticatedApp";

const App = () => {
  const { loginWithRedirect, isLoading, error, user, isAuthenticated } =
    useAuth0();
  if (error) {
    return <div>{error.message}</div>;
  }
  if (isLoading) {
    return <div>Loading</div>;
  } else if (!isAuthenticated || !user) {
    void loginWithRedirect();
    return <></>;
  } else {
    return <AuthenticatedApp user={user} />;
  }
};

export default App;

import styled from "styled-components/macro";
import { AppColors } from "../Styling";

type InfoTextProps = {
  weight?: number;
};

/* NOTE: this does not conform to the 4px grid. This one instance is approved by the powers that be */
export const InfoText1 = styled.span<InfoTextProps>`
  font-weight: ${props => props.weight || 400};
  font-size: 14px;
`;

export const InfoText2 = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${AppColors.neutral.darkGrey};
`;

export const InfoText3 = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${AppColors.neutral.darkestGrey};
`;

export const NavItemActive = styled.span`
  font-weight: 500;
  font-size: 15px;
`;

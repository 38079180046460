import styled from "styled-components/macro";

/**
 * Component that will spread its direct children out as far away from each
 * other as possible. With one element, this produces a left-aligned child. With two,
 * the children will be at the extreme left and right sides of the layout. With three,
 * they will be left, center, and right horizontally in the layout.
 */
export const BookendLayout = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

export default BookendLayout;
import { AppColors } from "components/common/Styling";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Nav: React.FC = styled.nav`
  background-color: ${AppColors.primary["msr-green"]};
  width: 300px;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Ul: React.FC = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  padding: 35px 0 0 25px;
`;

export const NavLink2 = styled(NavLink)`
  color: ${AppColors.neutral.darkGrey};
  font-size: 28px;
  font-weight: 600;
  padding: 25px 0 0 15px;
  text-decoration: none;
  position: relative;
  transition: color .5s ease;

  &:before {
    position: absolute;
    top: 45%;
    bottom: 0;
    left: 0;
    right: 100%;
    margin: auto;
    width: 3px;
    content: '.';
    color: transparent;
    background: ${AppColors.neutral["light-gray-9"]};
    height: 0%;
    transition: height .5s ease;
  }
  
  &:hover:before {
    height: 25px;
  }
  
  &:hover {
    color: ${AppColors.neutral["light-gray-9"]};
    cursor: pointer;
  }
  

  &.active:before {
    height: 25px;
  }

  &.active {
    color: ${AppColors.neutral["light-gray-9"]};
  }
`;
import styled from "styled-components/macro";

export const Body1 = styled.p`
  font-weight: 500;
  line-height: 26px;
  font-size: 16px;
`;
export const Body2 = styled.p`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
`;

export default Body1;

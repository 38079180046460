import { Role } from "components/app/users/types";
import React, { ReactNode } from "react";
import { useApolloClient, gql } from "@apollo/client";
/*
 * This will get / provide the Hatch User information to the app
 * which is very different than the Auth user, which just has name
 * email and token. This user will have roles, timezone, etc.
 */

// user query
const fetchUserDataQuery = gql`
  query contextUserById($id: String!) {
    getUserById(id: $id) {
      firstName
      lastName
      id
      email
      organizationId
      roles
      timezone
    }
  }
`;
interface UserDataQueryVars {
  id: string;
}
export type HatchUserData = {
  id: string /** Hatch user id */;
  email: string;
  firstName: string;
  lastName: string;
  organizationId: string;
  roles: Role[];
  timezone: string;
};
type UserDataQueryReturn = {
  getUserById?: HatchUserData;
};

// what do we need to pass into the component we wrap the app in (if anything)
interface UserProviderProps {
  children: ReactNode;
  userId: string;
}

// since we will not have a user to start, we have to also handle undefined
// but don't worry, we will never pass that back out
const UserDataContext =
  React.createContext<{ userData: HatchUserData } | undefined>(undefined);

// component that wraps the app and holds the (globalish) state
const UserDataProvider = (props: UserProviderProps) => {
  const [userData, setUserData] = React.useState<HatchUserData | null>(null);
  const client = useApolloClient();

  const getUserData = async () => {
    try {
      console.log("About to fetch the user data");
      const { data, errors } = await client.query<
        UserDataQueryReturn,
        UserDataQueryVars
      >({
        query: fetchUserDataQuery,
        variables: { id: props.userId },
      });
      if (data.getUserById) {
        console.log("got user data back, all is wonderful", data.getUserById);
        setUserData(data.getUserById);
      } else {
        console.error(
          "there was a problem fetching the user data:",
          data,
          errors,
        );
      }
    } catch (error) {
      console.error("context failed to get user data:", error);
    }
  };
  React.useEffect(() => {
    console.log("Provider rendered: fetch userdata effect");
    getUserData();
  }, []);
  return userData === null ? (
    <p>User Data Context Loading...</p>
  ) : (
    <UserDataContext.Provider value={{ userData }}>
      {props.children}
    </UserDataContext.Provider>
  );
};

function useUserData() {
  const context = React.useContext(UserDataContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}
export { useUserData, UserDataProvider };

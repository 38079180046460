import { datadogLogging } from "./datadogLogging";
import { mixpanelTracking } from "./tracking";

export * from "./events"

export const logging = {
  logEvent: (eventName: string, eventProperties?: Record<string, any>) => {
    mixpanelTracking.trackEvent(eventName, eventProperties);
  },
  logError: (
    eventName: string,
    eventProperties?: Record<string, any>,
    error: unknown = {},
  ) => {
    mixpanelTracking.trackError(eventName, eventProperties, error);
  },
  // maybe send more someday?
  userAuth: (userId: string) => {
    mixpanelTracking.trackLogin(userId);
    datadogLogging.setDDUserContext({ userId });
  }
}
import { lazy, Suspense } from "react";
import { SupportalMain } from "../SupportalMain";
import { Route, Routes } from "react-router";

/* =============================================================== *\
   HEY YOU!

   Don't import pages with `import SomePage ...` in here! Use the
   crazy `const SomePage = lazy(() => import(...))` way below. Why?
   Because then we lazy load everything and we don't have to import
   lots of code (like material-ui) that most things never use.

   Things have to be exported as `default` for this to work btw.
\* =============================================================== */
const UserDeactivation = lazy(
  () => import("components/app/users/UserDeactivation"),
);
const ResendWelcomeEmail = lazy(
  () => import("components/app/users/ResendWelcomeEmail/ResendWelcomeEmail"),
);
const AddBuildingsToUser = lazy(
  () => import("components/app/users/AddBuildingsToUser/AddBuildingsToUser"),
);

const RemoveBuildingsFromUser = lazy(
  () =>
    import(
      "components/app/users/RemoveBuildingsFromUser/RemoveBuildingsFromUser"
    ),
);

const SetupODAS = lazy(() => import("components/app/Ingest/ODASSetup"));
const LookupODAS = lazy(
  () => import("components/app/Ingest/ODASSetup/pages/LookupODAS"),
);
const RegisterODAS = lazy(
  () => import("components/app/Ingest/ODASSetup/pages/RegisterODAS"),
);
const RegisterODASPoints = lazy(
  () => import("components/app/Ingest/ODASSetup/pages/RegisterODASPoints"),
);
const UpdateODASPassword = lazy(
  () => import("components/app/Ingest/ODASSetup/pages/UpdateODASPassword"),
);
const S2Provisioning = lazy(
  () => import("components/app/Ingest/S2Provisioning"),
);
const S2BulkProvisioning = lazy(
  () => import("components/app/Ingest/S2Provisioning/pages/S2BulkProvisioning"),
);
const S2Lookup = lazy(
  () => import("components/app/Ingest/S2Provisioning/pages/S2InspectorPage"),
);

const SetupSMT = lazy(() => import("components/app/Ingest/SMTSetup"));

// const LookupSMT = lazy(
//     () => import("components/app/Ingest/SMTSetup/pages/LookupSMT"),
// );

const RegisterSMT = lazy(
  () => import("components/app/Ingest/SMTSetup/pages/RegisterSMT"),
);

const RegisterSMTPoint = lazy(
  () =>
    import(
      "components/app/Ingest/SMTSetup/pages/RegisterSMTPoint/manual/RegisterSMTPoint"
    ),
);

const BulkRegisterSMTPoint = lazy(
  () =>
    import(
      "components/app/Ingest/SMTSetup/pages/RegisterSMTPoint/BulkUpload/BulkRegisterSMTPoints"
    ),
);

const SetupUrjanet = lazy(() => import("components/app/Ingest/UrjanetSetup"));
const RegisterUrjanetPoints = lazy(
  () => import("components/app/Ingest/UrjanetSetup/pages/RegisterUrjanetPoints"),
);

const SetupHttp = lazy(() => import("components/app/Ingest/HttpSetup"));
const RegisterHttpPoints = lazy(
  () => import("components/app/Ingest/HttpSetup/pages/RegisterHttpPoints"),
);

const IngestPoints = lazy(() => import("components/app/Ingest/IngestPoints"));
const UpdateIngestPoint = lazy(
  () => import("components/app/Ingest/IngestPoints/pages/UpdateIngestPoint"),
);
const RegisterNewUser = lazy(
  () => import("components/app/users/RegisterNewUser/RegisterNewUser"),
);
const UpdateUser = lazy(
  () => import("components/app/users/UpdateUser/UpdateUser"),
);
const AddRoleToUser = lazy(
  () => import("components/app/users/AddRoleToUser/AddRoleToUser"),
);
const LookUpUser = lazy(
  () => import("components/app/users/LookUpUserByEmail/LookUpUserByEmail"),
);

const LookUpUserByOrg = lazy(
  () => import("components/app/users/LookupUserByOrg/LookupUserByOrg"),
);

const UpdateBillManagement = lazy(
  () =>
    import("components/app/users/UpdateBillManagement/UpdateBillManagement"),
);
const BackendConfiguration = lazy(
  () =>
    import("components/app/Sources/BackendConfiguration/BackendConfiguration"),
);
const CreateServiceAccountAndMeter = lazy(
  () =>
    import(
      "components/app/Sources/BackendConfiguration/CreateServiceAccountAndMeter"
    ),
);
const CreateAndAssignMeter = lazy(
  () =>
    import("components/app/Sources/BackendConfiguration/CreateAndAssignMeter"),
);
const AssignMeterToServiceAccount = lazy(
  () =>
    import(
      "components/app/Sources/BackendConfiguration/AssignMeterToServiceAccount"
    ),
);
const SearchServiceAccount = lazy(
  () => import("components/app/Sources/SearchSources/SearchSources"),
);
const UpdateServiceAccount = lazy(
  () =>
    import("components/app/Sources/UpdateServiceAccount/UpdateServiceAccount"),
);
const MoveMeter = lazy(
  () => import("components/app/Building Model/meters/MoveMeter/MoveMeter"),
);
const UpdateMeter = lazy(
  () => import("components/app/Building Model/meters/UpdateMeter/UpdateMeter"),
);
const RetireMeter = lazy(
  () => import("components/app/Building Model/meters/RetireMeter/RetireMeter"),
);

const AddMeter = lazy(
  () => import("components/app/Building Model/meters/AddMeter/AddMeter"),
);

const RestoreStagingPoint = lazy(
  () =>
    import(
      "components/app/Building Model/meters/RestoreStagingPoint/RestoreStagingPoint"
    ),
);

const RegisterBuilding = lazy(
  () =>
    import(
      "components/app/Building Model/building/RegisterBuilding/RegisterBuilding"
    ),
);

const UpdateMeasureDefaultAssignees = lazy(
  () => import("components/app/Measures/UpdateMeasureDefaultAssignees"),
);

const MeterMeasuresForBuilding = lazy(
  () => import("components/app/Measures/MeterMeasuresForBuilding"),
);

const ManageBuildingIntegrationMapping = lazy(
  () =>
    import(
      "components/app/Integrations/ManageBuildingIntegrationMapping/ManageBuildingIntegrationMapping"
    ),
);

const ManageBuildingBaselineModels = lazy(
  () =>
    import(
      "components/app/BuildingBaselines/ManageBaselineModels/ManageBuildingBaselinesModels"
    ),
);

const IngestMain = lazy(() => import("components/app/Ingest/IngestMain"));
const UsersMain = lazy(() => import("components/app/users/UsersMain"));
const SourcesMain = lazy(() => import("components/app/Sources/SourcesMain"));
const BuildingModelMain = lazy(() => import("components/app/Building Model/BuildingModelMain"));
const MeasuresMain = lazy(() => import("components/app/Measures/MeasuresMain"));
const IntegrationsMain = lazy(() => import("components/app/Integrations/IntegrationsMain"))
const BaselinesMain = lazy(() => import("components/app/BuildingBaselines/BaselinesMain"))

export const SupportalRoutes = () => {
  return (
    <Suspense fallback={<h5>loading....</h5>}>
      <Routes>
        <Route path="/" element={<SupportalMain />} />
        <Route path="/s2Provisioning" element={<S2Provisioning />} />
        <Route
          path="/s2Provisioning/bulk-registration"
          element={<S2BulkProvisioning />}
        />
        <Route path="/s2Provisioning/lookup" element={<S2Lookup />} />
        <Route path="/ODASSetup" element={<SetupODAS />} />
        <Route path="/ODASSetup/LookupODAS" element={<LookupODAS />} />
        <Route path="/ODASSetup/RegisterODAS" element={<RegisterODAS />} />
        <Route
          path="/ODASSetup/UpdateODASPassword"
          element={<UpdateODASPassword />}
        />
        <Route
          path="/ODASSetup/RegisterODASPoints"
          element={<RegisterODASPoints />}
        />
        <Route path="/ingest/points" element={<IngestPoints />} />
        <Route
          path="/ingest/points/update-ingest-point"
          element={<UpdateIngestPoint />}
        />
        <Route path="/smt-setup" element={<SetupSMT />} />
        {/*<Route*/}
        {/*    path="/SMTSetup/LookupSMT"*/}
        {/*    element={<LookupSMT />}*/}
        {/*/>*/}
        <Route path="/smt-setup/register-smt" element={<RegisterSMT />} />
        <Route
          path="/smt-setup/register-smt/manual"
          element={<RegisterSMTPoint />}
        />
        <Route
          path="/smt-setup/register-smt/bulk-upload"
          element={<BulkRegisterSMTPoint />}
        />
        <Route path="/UrjanetSetup" element={<SetupUrjanet />} />
        <Route
          path="/UrjanetSetup/RegisterUrjanetPoints"
          element={<RegisterUrjanetPoints />}
        />
        <Route path="/HttpSetup" element={<SetupHttp />} />
        <Route
          path="/HttpSetup/RegisterHttpPoints"
          element={<RegisterHttpPoints />}
        />
        <Route path="/users/deactivate-user" element={<UserDeactivation />} />
        <Route path="/users/register-user" element={<RegisterNewUser />} />
        <Route path="/users/update-user" element={<UpdateUser />} />
        <Route
          path="/users/resend-welcome-email"
          element={<ResendWelcomeEmail />}
        />
        <Route
          path="/users/add-buildings-to-user"
          element={<AddBuildingsToUser />}
        />
        <Route
          path="/users/remove-buildings-from-user"
          element={<RemoveBuildingsFromUser />}
        />
        <Route path="/users/add-role" element={<AddRoleToUser />} />
        <Route path="/users/look-up-user" element={<LookUpUser />} />
        <Route
          path="/users/look-up-user-by-org"
          element={<LookUpUserByOrg />}
        />
        <Route
          path="/users/update-bill-management"
          element={<UpdateBillManagement />}
        />
        <Route
          path="/sources/backend-configuration"
          element={<BackendConfiguration />}
        />
        <Route
          path="/sources/backend-configuration/create-service-account-meter"
          element={<CreateServiceAccountAndMeter />}
        />
        <Route
          path="/sources/backend-configuration/create-assign-meter"
          element={<CreateAndAssignMeter />}
        />
        <Route
          path="/sources/backend-configuration/assign-meter-service-account"
          element={<AssignMeterToServiceAccount />}
        />
        <Route
          path="/sources/search-sources"
          element={<SearchServiceAccount />}
        />
        <Route
          path="/sources/update-service-account"
          element={<UpdateServiceAccount />}
        />
        <Route
          path="/building-model/meters/move-meter"
          element={<MoveMeter />}
        />
        <Route
          path="/building-model/meters/update-meter"
          element={<UpdateMeter />}
        />
        <Route
          path="/building-model/meters/retire-meter"
          element={<RetireMeter />}
        />
        <Route path="/building-model/meters/add-meter" element={<AddMeter />} />
        <Route
          path="/building-model/meters/restore-staging-point"
          element={<RestoreStagingPoint />}
        />
        <Route
          path="/building-model/buildings/register-building"
          element={<RegisterBuilding />}
        />
        <Route
          path={"/measures/building-default-assignee"}
          element={<UpdateMeasureDefaultAssignees />}
        />
        <Route
          path={"/measures/manage-meter-measures"}
          element={<MeterMeasuresForBuilding />}
        />
        <Route
          path="/integrations/building-mapping"
          element={<ManageBuildingIntegrationMapping />}
        />
        <Route
          path="/building-baselines/models"
          element={<ManageBuildingBaselineModels />}
        />
        <Route path="/ingest" element={<IngestMain />} />
        <Route path="/users" element={<UsersMain />} />
        <Route path="/sources" element={<SourcesMain />} />
        <Route path="/building-model" element={<BuildingModelMain />} />
        <Route path="/measures" element={<MeasuresMain />} />
        <Route path="/integrations" element={<IntegrationsMain />} />
        <Route path="/baselines" element={<BaselinesMain />} />
      </Routes>
    </Suspense>
  );
};

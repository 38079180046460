import styled from "styled-components/macro";
import { propsToPx } from './Layout';

interface BoxProps {
  padding?: number | number[],
  margin?: number | number[],
}

/**
 * It is a box! You can put things in it. You can also give it
 * margins and padding! A single value will be applied to all sides.
 * An array of values will be applied in the same manner that CSS
 * shorthand is: [ top, right, bottom, left ] or [vertical, horizontal]
 */
export const Box = styled.div<BoxProps>`
  margin: ${props => propsToPx(props.margin)};
  padding: ${props => propsToPx(props.padding)};
`
export default Box;

import { MessageBoxKind } from "components/common/MessageBox/MessageBox";

export enum Role {
  ADMIN = "ADMIN",
  SUPPORT_ADMIN = "SUPPORT_ADMIN",
  ALL_BUILDINGS_FOR_ORGANIZATION = "ALL_BUILDINGS_FOR_ORGANIZATION",
  CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
  CUSTOMER_PREVIEW = "CUSTOMER_PREVIEW",
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  FINANCIAL_ONLY = "FINANCIAL_ONLY",
  INTERNAL_SUPPORT = "INTERNAL_SUPPORT",
  PREVIEW = "PREVIEW",
  READ_ONLY = "READ_ONLY",
  FIELD_OPERATOR = "FIELD_OPERATOR",
}

export type WorkflowStepResponse = {
  message?: string;
  success: boolean;
};

export enum TimezoneEnum {
  "America/New_York" = "America/New_York",
  "America/Chicago" = "America/Chicago",
  "America/Denver" = "America/Denver",
  "America/Phoenix" = "America/Phoenix",
  "America/Los_Angeles" = "America/Los_Angeles",
  "America/St_Johns" = "America/St_Johns",
  "America/Halifax" = "America/Halifax",
  "America/Toronto" = "America/Toronto",
  "America/Coral_Harbor" = "America/Coral_Harbor",
  "America/Winnipeg" = "America/Winnipeg",
  "America/Regina" = "America/Regina",
  "America/Edmonton" = "America/Edmonton",
  "America/Whitehorse" = "America/Whitehorse",
  "America/Vancouver" = "America/Vancouver",
  "Europe/London" = "Europe/London",
  "Europe/Dublin" = "Europe/Dublin",
  "Europe/Berlin" = "Europe/Berlin",
}

export enum ReportIdentifier {
  BUILDING_ELECTRICITY_DAILY_DEMAND_PROFILE = "BUILDING_ELECTRICITY_DAILY_DEMAND_PROFILE",
  BUILDING_HOURLY_BASELINES = "BUILDING_HOURLY_BASELINES",
  BUILDING_DAILY_BASELINES = "BUILDING_DAILY_BASELINES",
  COMMODITY_CHART = "COMMODITY_CHART",
  DAILY_MEASURE_SUMMARY = "DAILY_MEASURE_SUMMARY",
  MEASURES_ASSIGNMENT = "MEASURES_ASSIGNMENT",
  MISSING_BILLS = "MISSING_BILLS",
  OPEN_MEASURES_SUMMARY = "OPEN_MEASURES_SUMMARY",
}

export enum RecurringPeriod {
  DAILY_AM = "DAILY_AM",
  WEEKDAYS_AM = "WEEKDAYS_AM",
  WEEKLY_MONDAY_AM = "WEEKLY_MONDAY_AM",
  MONTHLY_FIRST_AM = "MONTHLY_FIRST_AM",
  QUARTERLY_FIRST_AM = "QUARTERLY_FIRST_AM",
  NOTIFICATION = "NOTIFICATION",
}

export type LogMessage = {
  message: string;
  kind: MessageBoxKind;
};
